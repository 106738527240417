@font-face {
    font-family: 'Luciole-Bold';
    src: url('./fonts/Luciole-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'Luciole-Regular';
    src: url('./fonts/Luciole-Regular.ttf') format('truetype');
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, 'Luciole-Bold',
        'Luciole-Regular';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
